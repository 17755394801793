import {apiCaller} from "./api/PXGAPICaller";
import {appEvents, appMessaging} from "./PXG";

export default class PXGSession
{
    constructor()
    {
        this.activeSession = false;
    }

    async isActive()
    {
        if (this.activeSession === true)
        {
            return true;
        }
        return await this.loadLast();
    }

    async loadLast()
    {
        this.sessionUID = window.localStorage.getItem("pxg.session.uid");
        this.session_start = window.localStorage.getItem("pxg.session.start");
        this.session_expires = window.localStorage.getItem("pxg.session.expires");

        if (this.sessionUID !== null && this.session_start !== null && this.session_expires !== null)
        {
            //Make sure the session is not expired
            if (Date.now() < this.session_expires)
            {
                this.activeSession = true;
                return this.activeSession;
            }
        }

        await this.activate();

        return this.activeSession;
    }

    async activate()
    {
        if (this.activeSession === true)
        {
            return;
        }

        await apiCaller.session((response) => {

            this.activeSession = true;
            this.sessionUID = response.sessionUID;
            this.session_start = response.startedAt;
            this.session_expires = response.expiresAt;

            window.localStorage.setItem("pxg.session.uid", this.sessionUID);
            window.localStorage.setItem("pxg.session.start", this.session_start);
            window.localStorage.setItem("pxg.session.expires", this.session_expires);

            appEvents.dispatch("session.start", this);

            this.#sessionLocation();
            this.#clientProfile();
        });
    }

    #sessionLocation()
    {
        if ("geolocation" in navigator)
        {
            navigator.geolocation.getCurrentPosition(
        (response) => {
                        this.#logLocation(response);
                    },
        (error) => {
                        appMessaging.showInfo("Location-based features will not be shown", 10000);
                    },
            {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0});
                    }
        else
        {
            appMessaging.showInfo("Location-based features disabled", 5000);
        }
    }

    #logLocation(position)
    {
        const attribute = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
            timestamp: position.timestamp
        }

        apiCaller.attribute("LOCATION", attribute);
    }

    #clientProfile()
    {

    }
}
