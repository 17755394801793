export default class AppMessaging
{
    constructor()
    {
    }

    setMessageRenderer(handler)
    {
        this.messageRenderer = handler;
    }

    showError(message, duration)
    {
        if (this.messageRenderer !== undefined)
        {
            this.messageRenderer("error", message, duration);
        }
    }

    showWarning(message, duration)
    {
        if (this.messageRenderer !== undefined)
        {
            this.messageRenderer("warning", message, duration);
        }
    }

    showInfo(message, duration)
    {
        if (this.messageRenderer !== undefined)
        {
            this.messageRenderer("info", message, duration);
        }
    }

    showSuccess(message, duration)
    {
        if (this.messageRenderer !== undefined)
        {
            this.messageRenderer("success", message, duration);
        }
    }
}
