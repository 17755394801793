import {useFrame, useLoader} from "@react-three/fiber";
import {useEffect, useRef} from "react";
import {Interactive} from "@react-three/xr";
import {appEvents} from "../../PXG";
import {TextureLoader} from "three";

export default function DSCSection({
    position,
    rotationSpeed = 0.005
})
{
    const sectionRef = useRef();

    const textureDSC = useLoader(TextureLoader, '/images/dsc.jpeg');

    useEffect(() => {

    }, [])

    useFrame((state, delta, frame) => {
        sectionRef.current.rotation.y += rotationSpeed;
    })

    function onSectionClick()
    {
        appEvents.dispatch("navigateTo", "/projects/dsc")
    }

    return <group ref={sectionRef}
                  position={position}>

        <Interactive onSelect={onSectionClick}>
            {/*<Billboard*/}
            {/*    follow={true}*/}
            {/*    onClick={onSectionClick}*/}
            {/*    position={[0,4.5,0]}*/}
            {/*    scale={0.6}>*/}
            {/*    <Text fontWeight={800}>Digital Supply Chain</Text>*/}
            {/*</Billboard>*/}
            <mesh position={[0,1.5,0]} onClick={onSectionClick}>
                <boxGeometry args={[5,5,5]} />
                <meshPhongMaterial map={textureDSC} wireframe={false} />
            </mesh>
        </Interactive>

    </group>
}
