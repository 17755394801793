export const pageContainer = {
    backgroundColor: '#141C21D8',
    pointerEvents: 'auto',
    width: window.innerWidth >= 1000 ? 1000: window.innerWidth - 10,
    alignItems: 'center'
};

export const breadcrumbBar = {
    backgroundColor: '#141414'
}
