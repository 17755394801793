import {Image} from "@react-three/drei";

export default function FloorImage({
    position,
    scale = 14.0,
    imageOpacity = 0.7,
    imageURL
})
{
    return <group
        rotation-x={-Math.PI/2} rotation-z={Math.PI/12} position={position} scale={scale} >
        <Image
            side={2}
            opacity={imageOpacity}
            transparent={true}
            url={imageURL} />
    </group>
}
