import * as THREE from "three";
import {useEffect} from "react";
import {Billboard, Text} from "@react-three/drei";
import {useFrame} from "@react-three/fiber";
import {Interactive} from "@react-three/xr";
import {appEvents} from "../../PXG";
import StationModel from "../objects/StationModel";

export default function CISection({
    position
})
{
    useEffect(() => {

    }, [])

    useFrame((state, delta, frame) => {

    })

    function onSectionClick()
    {
        appEvents.dispatch("navigateTo", "/projects/ci")
    }

    return <group position={position} scale={1.0}>

        <Interactive onSelect={onSectionClick}>
            {/*<Billboard*/}
            {/*    onClick={onSectionClick}*/}
            {/*    follow={true}*/}
            {/*    position={[0, 5.7, 0]}*/}
            {/*    scale={0.6}>*/}
            {/*    <Text fontWeight={800}>Critical Infrastructure</Text>*/}
            {/*</Billboard>*/}
            <StationModel onClick={onSectionClick} position={[0, 0.75, 0]} />
        </Interactive>

    </group>
}
