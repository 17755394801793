import axios from "axios";
import {appMessaging, appSession} from "../PXG";

export default class PXGAPICaller
{
    constructor()
    {

    }

    contact(payload, callback)
    {
        const serviceURL = process.env.REACT_APP_API_ROOT + "contact";

        const config = {
            headers: {
                "X-Api-Key": process.env.REACT_APP_API_KEY
            }
        }

        payload["sessionUID"] = appSession.sessionUID;

        axios.post(serviceURL, payload, config)
            .then((response) => {
                callback(true, "");
            })
            .catch((error) => {
                callback(false, error);
            });
    }

    attribute(attributeKey, payload)
    {
        const serviceURL = process.env.REACT_APP_API_ROOT + "session/attribute";

        const config = {
            headers: {
                "X-Api-Key": process.env.REACT_APP_API_KEY
            }
        }

        payload["sessionUID"] = appSession.sessionUID;
        payload["attributeKey"] = attributeKey;

        axios.post(serviceURL, payload, config)
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    session(callback)
    {
        const serviceURL = process.env.REACT_APP_API_ROOT + "session";

        const config = {
            headers: {
                "X-Api-Key": process.env.REACT_APP_API_KEY
            }
        }

        const payload = {
            "details": "here"
        };

        axios.post(serviceURL, payload, config)
            .then((response) => {

                if (response.status === 200)
                {
                    callback(response.data);
                }
            }).catch((reason) => {
            appMessaging.showError("Error activating session", 10000);
        });
    }

    usage(origin, target, action)
    {
        const serviceURL = process.env.REACT_APP_API_ROOT + "session/usage";

        const config = {
            headers: {
                "X-Api-Key": process.env.REACT_APP_API_KEY
            }
        }

        const payload = {
            "sessionUID": appSession.sessionUID,
            "origin": origin,
            "target": target,
            "action": action
        };

        axios.post(serviceURL, payload, config)
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    view(route)
    {
        const serviceURL = process.env.REACT_APP_API_ROOT + "session/view";

        const config = {
            headers: {
                "X-Api-Key": process.env.REACT_APP_API_KEY
            }
        }

        const payload = {
            "sessionUID": appSession.sessionUID,
            "route": route
        };

        axios.post(serviceURL, payload, config)
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => {
                // console.log(error);
            });
    }
}

export const apiCaller = new PXGAPICaller();
