import React, {Fragment} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './style.css';
import AppMain from "./AppMain";
import App3D from "./App3D";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Fragment>

        <App3D />

        <AppMain />

    </Fragment>
);
