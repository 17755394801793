import {MapControls, shaderMaterial, Sparkles, Stars} from "@react-three/drei";
import * as THREE from 'three';
import {useFrame} from "@react-three/fiber";
import {Suspense, useRef} from "react";
import {floorFragmentShader, floorVertexShader} from "./shaders/floorShader";
import AboutSection from "./sections/AboutSection";
import {Interactive, useXR} from "@react-three/xr";
import HexFloor from "./objects/HexFloor";
import DSCSection from "./sections/DSCSection";
import BroadtaskSection from "./sections/BroadtaskSection";
import CISection from "./sections/CISection";
import FloorImage from "./objects/FloorImage";
import CyberBrainModel from "./objects/CyberBrainModel";
import ContactSection from "./sections/ContactSection";

export default function Experience()
{
    const {controllers, isPresenting, isHandTracking, player, session} = useXR();

    const floorMesh = useRef();
    const floorMaterial = useRef();

    const floorUniforms = {
        uTime: { value: 0.0 },
        uBigWavesElevation: { value: 0.4 },
        uBigWaveFrequency: { value: new THREE.Vector2(1.75, 1.25) },
        uBigWavesSpeed: { value: 0.25 },

        uSmallWavesElevation: { value: 0.03},
        uSmallWavesFrequency: { value: 0.01},
        uSmallWavesSpeed: { value: 0.1},
        uSmallWavesIterations: { value: 3.0},

        uDepthColor: { value: new THREE.Color('#1FB04B') },
        uSurfaceColor: { value: new THREE.Color('#2DE0DA') },
        uColorOffset: { value: 0.08 },
        uColorMultiplier: { value: 3.0 }
    };

    useFrame((state, delta, frame) => {
        if (floorMaterial.current)
        {
            floorMaterial.current.uniforms.uTime.value += delta * 3;
        }
    })

    function onFloorClick(event)
    {
    }

    function onMovePlayer(event)
    {

    }

    return <>

        <color args={['#1A1A2A']} attach="background" />

        {/*<OrbitControls makeDefault />*/}
        <MapControls makeDefault />

        <directionalLight intensity={10.0} position={[0, 0, 20]} />

        <ambientLight intensity={0.25} />

        <mesh ref={floorMesh} position-y={ - 1 }
              rotation-x={ - Math.PI * 0.5 }
              rotation-z={Math.PI/2}
              scale={ 1 } onClick={onFloorClick}>
            {/*<planeGeometry args={[32,32,256,256]} />*/}

            <ringGeometry args={[0, 36, 6, 16, Math.PI/4]} />
            <shaderMaterial
                ref={floorMaterial}
                side={THREE.DoubleSide}
                fragmentShader={floorFragmentShader}
                vertexShader={floorVertexShader}
                uniforms={floorUniforms}
                wireframe={false}
            />
        </mesh>

        <Interactive onMove={onMovePlayer}>

            <HexFloor position={[0, 0.0, 0]}  />

            <FloorImage position={[0,0.2,0]} scale={14.0} imageURL="/floor/center.png" />
            <Suspense fallback={null}>
            </Suspense>

            <FloorImage position={[-13.9, 0.2, -3.61]} scale={14.0} imageURL="/floor/dsc.png" />
            <Suspense fallback={null}>
                <DSCSection position={[-13.9, 3.5, -3.61]} />
            </Suspense>

            <FloorImage position={[-3.61, 0.2, -13.9]} scale={14.0} imageURL="/floor/ci.png" />
            <Suspense fallback={null}>
                <CISection position={[-3.61, 3.5, -13.9]}/>
            </Suspense>

            <FloorImage position={[9.9, 0.2, -9.9]} scale={14.0} imageURL="/floor/sig.png" />
            <Suspense fallback={null}>
                <CyberBrainModel position={[9.9, 2, -9.9]} scale={1.4} />
            </Suspense>

            <FloorImage position={[13.5, 0.2, 3.61]} scale={14.0} imageURL="/floor/tr3ad.png" />
            <Suspense fallback={null}>
            </Suspense>

            <FloorImage position={[3.61, 0.2, 13.5]} scale={14.0} imageURL="/floor/p0lar.png" />
            <Suspense fallback={null}>
            </Suspense>

            <FloorImage position={[-9.9, 0.2, 9.9]} scale={14.0} imageURL="/floor/broadtask.png" />
            <Suspense fallback={null}>
                <BroadtaskSection position={[-9.0, 3.0, 9.9]}/>
            </Suspense>

            <Suspense fallback={null}>
                {/*<FloorImage position={[-17.1,0.2,-17.1]} scale={14.0} imageURL="/floor/contact.png" />*/}
            </Suspense>

            <Suspense fallback={null}>
                {/*<FloorImage position={[6.4,0.2,-23.4]} scale={14.0} imageURL="/floor/about.png" />*/}
            </Suspense>

            <FloorImage position={[17.1,0.2,17.1]} scale={14.0} imageURL="/floor/about.png" />
            <Suspense fallback={null}>
                <AboutSection position={[17.1, 2, 17.1]} scale={1.4} />
            </Suspense>

            <FloorImage position={[-6.4,0.2,23.4]} scale={14.0} imageURL="/floor/contact.png" />
            <Suspense fallback={null}>
                <ContactSection position={[-6.4,3.0,23.4]} scale={1.0} />
            </Suspense>

            <FloorImage position={[23.4, 0.2, -6.4]} scale={14.0} imageURL="/floor/inrtrust.png" />
            <Suspense fallback={null}>
                {/*<FloorImage position={[-23.4, 0.2, 6.4]} scale={14.0} imageURL="./floor/sig.png" />*/}
            </Suspense>

            <Suspense fallback={null}>
                {/*<FloorImage position={[-23.4, 0.2, 6.4]} scale={14.0} imageURL="./floor/sig.png" />*/}
            </Suspense>

            <Suspense fallback={null}>
                {/*<FloorImage position={[-17.1, 0.2, -17.1]} scale={14.0} imageURL="./floor/sig.png" />*/}
            </Suspense>

        </Interactive>

        <Sparkles
            size={3}
            scale={[64, 64, 64]}
            position-y={0}
            speed={0.75}
            count={2000}
            color={'#1FB04B'}
        />

        <Stars radius={80} depth={20} count={10000} factor={6} saturation={0} fade speed={1} />

        </>;
}
