import {Breadcrumbs, Button, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {breadcrumbBar} from "../../AppStyles";

const PageHeader = ({
    title,
    // subtitle,
    mapBC = []
}) => {

    const navigate = useNavigate();

    function onClose()
    {
        navigate("/");
    }

    return (
        <Stack direction="column" width="100%" spacing={0}>

            <Stack direction="row" alignItems="center" paddingLeft={1} justifyContent="space-between" width="100%" sx={breadcrumbBar}>
                <Breadcrumbs>
                    <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                    {
                        mapBC === null ?
                            <></>:
                            mapBC.map((nextLink) => {
                                return <Link key={"bc_" + nextLink.route} component={RouterLink} to={nextLink.route} underline={"hover"}>{nextLink.display}</Link>
                            })
                    }
                    <Typography>{title}</Typography>
                </Breadcrumbs>
                <Stack direction="row">
                    <Button onClick={onClose}>X</Button>
                </Stack>
            </Stack>

            {/*<Stack direction="row" padding={1} justifyContent="space-between" width="100%">*/}
            {/*    <Stack direction="column" useFlexGap flexWrap="wrap" width="100%">*/}
            {/*        <Typography variant="h5">{title}</Typography>*/}
            {/*        <Typography variant="subtitle1">{subtitle}</Typography>*/}
            {/*    </Stack>*/}
            {/*</Stack>*/}

        </Stack>
    )
}

export default PageHeader;
