export default class AppEvents
{
    constructor()
    {
        this.registry = {
            //Contains topics and an array of handlers (functions) like "topic": []
            // Or "login": [ onLogin, loginEvent]
        }
    }

    register(topic, handler)
    {
        if (this.registry[topic] === undefined)
        {
            this.registry[topic] = [];
        }

        const alreadyRegistered = this.registry[topic].includes(handler, 0);
        if (alreadyRegistered === false)
        {
            this.registry[topic].push(handler);
        }
    }

    dispatch(topic, message)
    {
        if (this.registry[topic] !== undefined)
        {
            for (const nextHandler of this.registry[topic])
            {
                nextHandler(topic, message);
            }
        }
    }
}
