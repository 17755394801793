import {Alert, Button, ClickAwayListener, Drawer, IconButton, Link, Snackbar, Stack, Typography} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import {appEvents, appMessaging} from "../PXG";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const AppHeader = (props) =>
{
    const navigate = useNavigate();

    const [messageShowing, setMessageShowing] = useState(false);
    const [message, setMessage] = useState("");
    const [messageSeverity, setMessageSeverity] = useState("info");
    const [messageDuration, setMessageDuration] = useState(5000);
    const [showMenu, setShowMenu] = React.useState(false);

    useEffect(() => {
        appMessaging.setMessageRenderer(onMessage);

        appEvents.register("navigateTo", (topic, message) => {
            navigate(message);
        });
    }, []);

    function onMessage(severity, message, duration)
    {
        setMessage(message);
        setMessageSeverity(severity);
        setMessageDuration(duration);
        setMessageShowing(true);
    }

    const onCloseMessage = (event, reason) =>
    {
        setMessageShowing(false);
    }

    const actionFragment = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onCloseMessage}
            />
        </React.Fragment>
    );

    const onShowMenu = () => {
        setShowMenu(true);
    };

    const onCloseMenu = () => {
        setShowMenu(false);
    }


    return (
        <Stack direction="row"
               padding={2}
               justifyContent="space-between" justifyItems="center">

            <Stack direction="row" spacing={1} justifyContent="space-between" width="100%">
                <Stack direction="row" spacing={1} alignItems="center" sx={{pointerEvents: 'auto'}}>
                    <Link component={RouterLink} to="/" underline={"hover"}>
                        <img src="/images/pxg_logo_white.png" height={64} />
                    </Link>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2} padding={1} sx={{pointerEvents: 'auto'}}>
                    <IconButton onClick={onShowMenu}>
                        <MenuIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Snackbar
                anchorOrigin={{horizontal: "center", vertical: "top"}}
                open={messageShowing}
                autoHideDuration={messageDuration}
                onClose={onCloseMessage}
                message=""
                action={actionFragment}>
                <Alert
                    sx={{ whiteSpace: 'pre-line' }}
                    onClose={onCloseMessage}
                    severity={messageSeverity}>{message}</Alert>
            </Snackbar>
            <Drawer id="appMenu" open={showMenu} anchor="right" style={{pointerEvents: 'auto'}} >

                <ClickAwayListener onClickAway={onCloseMenu}>

                <Stack height={"100%"} direction="column" spacing={2} padding={2} sx={{backgroundColor: "#141C21D8"}}>
                    <Stack direction="row" width="100%" justifyContent={"flex-end"}>
                        <IconButton onClick={onCloseMenu}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Link component={RouterLink} to='/' fontSize={16} sx={{color: 'white'}} onClick={onCloseMenu}>Home</Link>
                    <Stack spacing={1} alignItems={'right'}>
                        <Typography color={"gray"} variant="h6">Projects</Typography>
                        <Stack direction="column" spacing={2} paddingLeft={4}>
                            <Link component={RouterLink} to='/projects/ci' fontSize={16} sx={{color: 'white'}} onClick={onCloseMenu}>Critical Infrastructure (CI)</Link>
                            <Link component={RouterLink} to='/projects/dsc' fontSize={16} sx={{color: 'white'}} onClick={onCloseMenu}>Digital Supply Chain (DSC)</Link>
                            <Link component={RouterLink} to='/projects/sig' fontSize={16} sx={{color: 'white'}} onClick={onCloseMenu}>Semantic Intelligence (SI)</Link>
                        </Stack>
                        <Typography color={"gray"} variant="h6">Products</Typography>
                        <Stack direction="column" spacing={2} paddingLeft={4}>
                            <Link component={RouterLink} to='/products/broadtask' fontSize={16} sx={{color: 'white'}} onClick={onCloseMenu}>BROADTASK</Link>
                        </Stack>
                        <Typography color={"gray"} variant="h6">Company</Typography>
                        <Stack direction="column" spacing={2} paddingLeft={4}>
                            <Link component={RouterLink} to='/about' fontSize={16} sx={{color: 'white'}} onClick={onCloseMenu}>About Us</Link>
                            <Link component={RouterLink} to='/contact' fontSize={16} sx={{color: 'white'}} onClick={onCloseMenu}>Contact Us</Link>
                        </Stack>
                    </Stack>
                </Stack>
            </ClickAwayListener>
        </Drawer>

        </Stack>
    )
};

export default AppHeader;
