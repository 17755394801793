import {Stack, Typography} from "@mui/material";
import {MouseRounded} from "@mui/icons-material";

const AppFooter = () => {

    return (
        <Stack direction="row" alignItems="inherit"
               padding={1} paddingTop={2} spacing={1} marginTop={2} marginBottom={2}
               justifyContent="space-between" useFlexGap flexWrap="wrap">

            <Typography paddingBottom={2} variant="subtitle2">Copyright &copy; 2024, All rights reserved</Typography>

            <Stack direction="row" paddingTop={0} spacing={2} sx={{flexDirection: { xs: "column", md: "row", pointerEvents: 'auto'}}}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography variant="caption">Drag</Typography>
                    <MouseRounded />
                    <Typography variant="caption">to move + SHIFT to rotate</Typography>
                </Stack>

                {/*<Link component={RouterLink} to="/privacy" underline={"hover"}>Privacy Policy</Link>*/}
                {/*<Link component={RouterLink} to="/cookies" underline={"hover"}>Cookie Policy</Link>*/}
                {/*<Link component={RouterLink} to="/terms" underline={"hover"}>Terms of Use</Link>*/}
            </Stack>
        </Stack>
    )
}

export default AppFooter;
