import {Stack} from "@mui/material";
import {useEffect} from "react";
import {apiCaller} from "../api/PXGAPICaller";
import {appSession} from "../PXG";

const HomePage = () =>
{
    useEffect(() => {

        //Ensure the session is active
        appSession.isActive();

        apiCaller.view("/");
    }, []);

    return <Stack direction="column"
                  padding={1} spacing={1}
                  justifyContent="space-between" alignItems="center">

    </Stack>
};

export default HomePage;
