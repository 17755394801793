import {Link, List, ListItem, Stack, Typography} from "@mui/material";
import {useEffect} from "react";
import {pageContainer} from "../../../AppStyles";
import PageHeader from "../../components/PageHeader";
import * as React from "react";
import {Link as RouterLink} from "react-router-dom";

const CIPage = () =>
{
    useEffect(() => {

    }, []);

    return <Stack direction="column" alignItems="center">

        <Stack direction="column"
               sx={pageContainer}
               spacing={1} padding={0}>

            <PageHeader title="Critical Infrastructure Protection"
                        mapBC={[
                            {route: "/", display: "Projects"},
                        ]}
            />

            <Stack direction="column" padding={2} spacing={1} maxHeight={window.innerHeight - 250} overflow="auto">

                <Typography variant="h5">
                    Critical Infrastructure Protection
                </Typography>

                <Typography variant="body1">
                    Every research project and product under development at Play XOR Go has some nexus to the critical infrastructure challenge.
                </Typography>
                <Typography variant="body1">
                    The world is more interconnected today than at any other time in history.
                    While this has fostered an unprecedented period of innovation and advancement, it has also created a vulnerability that we have never encountered: the ability for a threat actor to create cascading, global disruption by attacking critical infrastructure.
                    Our technical advancements have enabled a rapid expansion in our global supply chains, which have resulted in dependencies on critical infrastructure organizations and assets around the world.
                    Recent global events have demonstrated the near immediate impact of critical infrastructure attacks on the global economy, supply chain, and essential services like food distribution, energy, transportation, health services, and finance.
                </Typography>
                <Typography variant="body1">
                    Protecting critical infrastructure is a security priority for our global supply chains and the essential services that we all depend upon.
                    The evolving scope of the number of threat vectors is one of challenges for critical infrastructure organizations: physical assets, cybersecurity, financial, supply chain, intellectual property, insider threat, and even geopolitics.
                    With the expansion of hybrid attacks, where adversaries use a combination of physical and electronic tactics to disrupt and even destroy assets, critical infrastructure organizations need integrated security solutions that can adapt and response to rapidly evolving tactics and vulnerabilities.
                    Another key aspect is securing the vital information, designs, and intellectual property that underpins every critical infrastructure enterprise.
                    This includes comprehensive vetting and protection of key personnel as well as strong procedural and technical means to protect intellectual property.
                </Typography>
                <Typography variant="body1">
                    Each of our projects and products are focused on address the critical infrastructure protection mission:
                </Typography>
                <Typography component="ul">
                    <Typography component="li">
                        Our <Link component={RouterLink} to={"/projects/dsc"} underline={"hover"}>Digital Supply Chain (DSC)</Link> project is building processes and supporting technology to evaluate, monitor, and secure the technology services that are essential to the operation of critical infrastructure systems and organizations.
                    </Typography>
                    <Typography component="li">
                        The <Link component={RouterLink} to={"/projects/sig"} underline={"hover"}>Semantic Intelligence (SI)</Link> project is addressing the escalating increase in data volume and cognitive overload that threat analysts are facing every day.
                    </Typography>
                    <Typography component="li">
                        Our <Link component={RouterLink} to={"/product/broadtask"} underline={"hover"}>BROADTASK</Link> product is a reactive tasking network to combine cloud services with physical devices, sensors, control systems, and other platforms to observe, react, and engage in real-time to any threat.
                    </Typography>
                    <Typography component="li">
                        Our P0LAR product provides real-time monitoring of threats to facilities and mobile teams, fusing signals processing with location intelligence data sources for real-time detection of threat actors in both the physical and cyber domain.
                    </Typography>
                    <Typography component="li">
                        Our INRTrust software platform was designed specifically to address the technical gaps in the control and management of intellectual property and sensitive information, providing a zero-trust architecture that ensures encryption at rest, access controls, and revokable trust from every endpoint.
                    </Typography>
                </Typography>
                <Typography variant="body1">
                    Please <Link component={RouterLink} to={"/contact"} underline={"hover"}>contact us</Link> to discuss how we can help protect your critical infrastructure assets from both physical and cyber threats.
                </Typography>

            </Stack>

        </Stack>

    </Stack>
};

export default CIPage;
