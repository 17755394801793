import * as THREE from "three";
import {useEffect, useRef} from "react";
import {Center, Text3D} from "@react-three/drei";
import {useFrame} from "@react-three/fiber";
import {Interactive} from "@react-three/xr";
import {appEvents} from "../../PXG";

const textMaterial = new THREE.MeshMatcapMaterial()

export default function ContactSection({
    position,
    scale = 0.5
})
{

    const refText = useRef();

    useEffect(() => {

    }, [])

    useFrame((state, delta, frame) => {

    })

    function onTextClick()
    {
        appEvents.dispatch("navigateTo", "/contact")
    }

    return <group position={position} scale={scale}>
        <Interactive onSelect={onTextClick}>
            <Text3D ref={refText}
                position={[-2.0,0,-0.4]}
                material={textMaterial}
                font="./fonts/helvetiker_regular.typeface.json"
                size={3.0}
                height={0.5}
                curveSegments={12}
                bevelEnabled={true}
                bevelThickness={0.02}
                bevelSize={0.05}
                bevelOffset={0}
                bevelSegments={5}
                onClick={onTextClick}
            >
                @
            </Text3D>
        </Interactive>
    </group>
}
