import * as THREE from "three";
import {useEffect, useRef, useState} from "react";
import {Center, GradientTexture, GradientType, Text3D, useMatcapTexture} from "@react-three/drei";
import {useFrame} from "@react-three/fiber";
import {Interactive} from "@react-three/xr";
import {appEvents} from "../../PXG";

const textMaterial = new THREE.MeshMatcapMaterial()

export default function AboutSection({
    position,
    scale = 0.5
})
{
    const logo = useRef();

    const [ matcapTexture ] = useMatcapTexture('6C6F76_CBD1D7_B2BDC7_A6B0BF', 256);
    const [ matcapBlack ] = useMatcapTexture('0A0A0A_A9A9A9_525252_747474', 256);
    const [ matcapWhite ] = useMatcapTexture('28292A_D3DAE5_A3ACB8_818183', 256);

    const [powerCount, setPowerCount] = useState(0);

    useEffect(() => {
        matcapTexture.colorSpace = THREE.SRGBColorSpace
        matcapTexture.needsUpdate = true;

        textMaterial.matcap = matcapTexture;
        textMaterial.needsUpdate = true;
    }, [])

    useFrame((state, delta, frame) => {
        logo.current.rotation.y += delta/3;
    })

    function handleClick()
    {
        appEvents.dispatch("navigateTo", "/about")
    }

    function onTextClick()
    {
        setPowerCount(powerCount + 1);

        //If the first click, then set a 10 sec completion timer
        if (powerCount == 1)
        {
            setInterval(() => {
                setPowerCount(0);
            }, 10000)
        }

        if (powerCount >= 8)
        {
            const audio = new Audio("/audio/power_up.mp3");
            audio.play();

            setPowerCount(0);
        }
    }

    return <group ref={logo} position={position} scale={scale} onClick={handleClick}>
        {/*Ring*/}
        <mesh position={[0,2,0]}>
            <torusGeometry args={[2.1, 0.05, 32, 64]} />
            <meshMatcapMaterial matcap={matcapWhite} />
        </mesh>
        {/*Inner Plane*/}
        <mesh position={[0,2,0]} sides={THREE.DoubleSide} rotation-x={Math.PI/2}>
            <cylinderGeometry args={[1.98, 1.98, 0.05, 64]} />
            <meshBasicMaterial>
                <GradientTexture
                    type={GradientType.Linear}
                    stops={[0, 1]}
                    colors={['#2DE0DA', '#1FB04B']} />
            </meshBasicMaterial>
        </mesh>
        {/*Pyramid*/}
        <mesh position={[-0.2,2,0]} rotation-x={Math.PI/2} rotation-z={-Math.PI/2}>
            <coneGeometry args={[1.7, 1.7, 32, 1]} />
            <meshMatcapMaterial matcap={matcapBlack} />
        </mesh>
        {/*Front Tube*/}
        <mesh position={[1.4,2,0]} rotation-z={-Math.PI/2}>
            <cylinderGeometry args={[0.1,0.1,1]} />
            <meshMatcapMaterial matcap={matcapBlack} />
        </mesh>
        {/*Top Tube*/}
        <mesh position={[0.5,2.5,0]} rotation-z={Math.PI/4} >
            <cylinderGeometry args={[0.11,0.10,1.5]} />
            <meshMatcapMaterial matcap={matcapBlack} />
        </mesh>
        {/*Bottom Tube*/}
        <mesh position={[0.5,1.5,0]} rotation-z={-Math.PI/4} >
            <cylinderGeometry args={[0.10,0.11,1.5]} />
            <meshMatcapMaterial matcap={matcapBlack} />
        </mesh>
        {/*Front Mount*/}
        <mesh position={[2.0,2.0,0]} >
            <boxGeometry args={[0.2,0.4,0.2]} />
            <meshMatcapMaterial matcap={matcapWhite} />
        </mesh>
        {/*Top Mount*/}
        <mesh position={[-1.05,3.68,0]} rotation-z={-Math.PI/3}>
            <boxGeometry args={[0.2,0.4,0.2]} />
            <meshMatcapMaterial matcap={matcapWhite} />
        </mesh>
        {/*Bottom Mount*/}
        <mesh position={[-1.05, 0.3,0]} rotation-z={Math.PI/3}>
            <boxGeometry args={[0.2,0.4,0.2]} />
            <meshMatcapMaterial matcap={matcapWhite} />
        </mesh>
        {/*<Center position={[0,-1,0]}>*/}
        {/*    <Interactive onSelect={onTextClick}>*/}
        {/*        <Text3D*/}
        {/*            material={textMaterial}*/}
        {/*            font="./fonts/helvetiker_regular.typeface.json"*/}
        {/*            size={0.9}*/}
        {/*            height={0.2}*/}
        {/*            curveSegments={12}*/}
        {/*            bevelEnabled={true}*/}
        {/*            bevelThickness={0.02}*/}
        {/*            bevelSize={0.05}*/}
        {/*            bevelOffset={0}*/}
        {/*            bevelSegments={5}*/}
        {/*            onClick={onTextClick}*/}
        {/*        >*/}
        {/*            PLAY XOR GO*/}
        {/*        </Text3D>*/}
        {/*    </Interactive>*/}
        {/*</Center>*/}
    </group>
}
