import {Canvas} from "@react-three/fiber";
import React from "react";
import {Controllers, Hands, VRButton, XR} from "@react-three/xr";
import Experience from "./pxg/xr/Experience";

export default function App3D()
{
    return <>

        <VRButton />
        <Canvas
            shadows={true}
            camera={ {
                fov: 75,
                near: 0.1,
                far: 400,
                position: [12, 15, 15]
            }}
        >
            <XR>
                <Controllers rayMaterial={{ color: '#38ff82'}}/>
                <Hands />

                <Experience />
            </XR>
        </Canvas>

        </>;
}
