import * as THREE from "three";
import {useEffect} from "react";
import {useFrame} from "@react-three/fiber";
import EarthSection from "../objects/EarthSection";
import NetworkSphere from "../objects/NetworkSphere";
import {Interactive} from "@react-three/xr";
import {appEvents} from "../../PXG";

const textMaterial = new THREE.MeshMatcapMaterial()

export default function BroadtaskSection({
    position
})
{
    useEffect(() => {

    }, [])

    useFrame((state, delta, frame) => {

    })

    function onSectionClick()
    {
        appEvents.dispatch("navigateTo", "/products/broadtask")
    }

    return <group position={position} scale={1.0}>

        <Interactive onSelect={onSectionClick}>
            <EarthSection onClick={onSectionClick} position={[0, 2.0, 0]} scale={3.0}/>
            <NetworkSphere scale={1.0} position={[0, 2.0, 0]} />
        </Interactive>

    </group>
}
