import {useFrame} from "@react-three/fiber";
import {useEffect, useRef} from "react";
import {useFBX} from "@react-three/drei";
import {Interactive} from "@react-three/xr";
import {AdditiveBlending, DoubleSide, SubtractiveBlending} from "three";

export default function StationModel({
    position,
    rotationSpeedY = 0.005,
    onClick
})
{
    const refStation = useRef();

    const stationModel = useFBX('/models/substation.fbx');

    useEffect(() => {

    }, [])

    useFrame((state, delta, frame) => {
        refStation.current.rotation.y += rotationSpeedY;
    })

    function handleClick(event)
    {
        onClick();
    }

    return <group position={position}>
        <Interactive onSelect={handleClick}>
            <mesh ref={refStation}>
                <primitive object={stationModel} scale={0.0015} position={[0, 0, 0]} />
            </mesh>
            <mesh scale={5.3} position={[0, -1.4, 0]} rotation-x={Math.PI/-2} onClick={handleClick}>
                <sphereGeometry args={[1,32,64,0,Math.PI]} />
                <meshBasicMaterial side={DoubleSide} transparency={true} opacity={0.05} blending={AdditiveBlending} wireframe={false} />
            </mesh>
            <mesh position={[0, -1.41, 0]} rotation-x={Math.PI/-2}>
                <circleGeometry args={[5.3,128]} />
                <meshBasicMaterial side={DoubleSide} color={"#780000"} wireframe={false} />
            </mesh>
        </Interactive>
    </group>
}
