import {useFrame} from "@react-three/fiber";
import {useEffect, useRef} from "react";
import {useFBX, useMatcapTexture, useTexture} from "@react-three/drei";
import {Interactive} from "@react-three/xr";
import {AdditiveBlending, DoubleSide} from "three";
import {appEvents} from "../../PXG";

export default function CyberBrainModel({
    position,
    rotateSpeedX = 0.0,
    rotateSpeedY = 0.002,
    rotateSpeedZ = 0.0
})
{
    const [ matcapBlack ] = useMatcapTexture('0A0A0A_A9A9A9_525252_747474', 256);
    const [ matcapTexture ] = useMatcapTexture('5C045C_BD0DBD_930493_A404A4', 256);

    const refBrain = useRef();

    const brainModel = useFBX('/models/cyberbrain.fbx');
    const brainTexture = useTexture('/textures/cyber/dirt.png');

    useEffect(() => {
        brainModel.traverse((child) => {
            if (child.isMesh === true)
            {
                if (child.name === "metal3_parts1_3")
                {
                    child.material.map = matcapTexture;
                }
                else
                {
                    child.material.map = matcapBlack;
                }
                child.material.needsUpdate = true;
            }
        });
    }, [])

    useFrame((state, delta, frame) => {
        refBrain.current.rotation.x += rotateSpeedX;
        refBrain.current.rotation.y += rotateSpeedY;
        refBrain.current.rotation.z += rotateSpeedZ;
    })

    function handleClick(event)
    {
        appEvents.dispatch("navigateTo", "/projects/sig")
    }

    return <group position={position}>
        <Interactive onSelect={handleClick}>
            <mesh position={[0, 2.5,0]} onClick={handleClick}>
                <sphereGeometry args={[3.5,16, 16]} />
                <meshBasicMaterial color={'#000000'} transparency={true} opacity={0.01} blending={AdditiveBlending} wireframe={false} />
            </mesh>
            <mesh ref={refBrain}>
                <primitive object={brainModel}
                           scale={0.3}
                           position={[0, 0, 0]}
                />
                <meshStandardMaterial wireframe={true} />
            </mesh>
        </Interactive>
    </group>
}
