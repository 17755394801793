import {useFrame, useLoader} from "@react-three/fiber";
import {useEffect, useRef} from "react";
import {AdditiveBlending, TextureLoader} from "three";
import {Interactive} from "@react-three/xr";

export default function EarthSection({
    position,
    scale,
    onClick,
    rotationSpeed = 0.005
})
{
    const earthRef = useRef();

    useEffect(() => {

    }, [])

    useFrame((state, delta, frame) => {
        earthRef.current.rotation.y += rotationSpeed;
    })

    function onEarthClick()
    {
        onClick();
    }

    const earthMap = useLoader(TextureLoader, '/textures/earth/8081_earthmap4k.jpg');
    const earthSpecMap = useLoader(TextureLoader, '/textures/earth/8081_earthspec4k.jpg');
    const earthBumpMap = useLoader(TextureLoader, '/textures/earth/8081_earthbump4k.jpg');
    const lightsMap = useLoader(TextureLoader, '/textures/earth/8081_earthlights4k.jpg');

    return <group ref={earthRef}
                  scale={scale}
                  position={position}>

        <mesh>
            <sphereGeometry args={[1,32,64]} />
            <meshPhongMaterial map={earthMap} specularMap={earthSpecMap} bumpMap={earthBumpMap} bumpScale={0.1} wireframe={false} />
        </mesh>

        <mesh>
            <sphereGeometry args={[1,32,64]} />
            <meshBasicMaterial map={lightsMap} blending={AdditiveBlending} wireframe={false} />
        </mesh>

        <Interactive onSelect={onEarthClick}>
            <mesh scale={1.02} onClick={onEarthClick}>
                <sphereGeometry args={[1,32,64]} />
                <meshBasicMaterial transparency={true} opacity={0.05} blending={AdditiveBlending} wireframe={false} />
            </mesh>
        </Interactive>

    </group>
}
