import {Link, Stack, Typography} from "@mui/material";
import {useEffect} from "react";
import {pageContainer} from "../../../AppStyles";
import PageHeader from "../../components/PageHeader";
import * as React from "react";
import {Link as RouterLink} from "react-router-dom";

const BroadtaskPage = () =>
{
    useEffect(() => {

    }, []);

    return <Stack direction="column" alignItems="center">

        <Stack direction="column"
               sx={pageContainer}
               spacing={1} padding={0}>

            <PageHeader title="BROADTASK"
                        // subtitle="A Reactive Tasking Platform to build advanced integrated systems that combine cloud services with physically deployed systems, computers, devices and sensors"
                        mapBC={[
                            {route: "/", display: "Products"},
                        ]}
            />

            <Stack direction="column" padding={2} spacing={1} maxHeight={window.innerHeight - 250} overflow="auto">

                <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center" paddingLeft={1} paddingRight={1}>
                    <img src="/products/broadtask/broadtask_logo.png" height={window.innerHeight >= 1000 ? 50: 30} />
                    {/*<Stack direction="row" spacing={1} alignItems="center">*/}
                    {/*    <Typography variant="subtitle1">Learn more at</Typography>*/}
                    {/*    <Link variant="subtitle1" href="https://www.broadtask.net" target="_blank">www.broadtask.net</Link>*/}
                    {/*</Stack>*/}
                </Stack>

                <Typography variant="body1">
                    Most advanced technology solutions today are not a single product, but an integrated system that couples a collection of services, platforms, data streams, and devices.
                    Creating these types of systems is complex, but the real challenge is changing them once they are in production, particularly when parts of the system are deployed around the world.
                    Despite a broad mission base, there are no software platforms to address all the challenges and required advancements needed in this area.
                    There is a rapidly emerging requirement for a next-generation system integration capability, one that can “react” to the real-world.
                </Typography>
                <Typography variant="body1">
                    This new approach to integrated systems needs to combine multiple data streams, services, algorithms, artificial intelligence functions, and sensor networks to observe real-world events, and then autonomously change the configurations of its systems and services to react and adapt.
                    This includes using AI to dynamically command-and-control services, devices, and external platforms.
                    Developers and even AI engines need to be able to code new functions, automatically deploying those new capabilities to a distributed production network.
                    The future global integrated systems will continuously observe, react, and adapt to the changing world.  We call this type of next-generation system <b>“reactive tasking networks.”</b>
                </Typography>
                <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
                    <Typography variant="body1">
                        Broadtask is a software platform to build reactive tasking networks that can adapt and react to real-world conditions, automatically configuring its own devices, services, algorithms, and processes and deploy new functionality to geospatially distributed systems.
                        Broadtask is a platform, not just a product.
                        Its primary purpose is to act as a foundation for system integrations, creating a platform to support ongoing production operations and enhancements.
                        Broadtask simplifies the integration of cloud services, virtual machines, dedicated computers, mobile phones, devices, sensors, web services, and other systems.
                        Designed to support a wide range of missions, Broadtask addresses critical challenges in complex system integrations including using limited access data sources, network security, on-platform processing, operating on limited communication networks, isolation of algorithms and processes, external systems interfaces, network attribution, integration of specialized hardware and devices, sensor interfaces, remote command-and-control, and over the air updates.
                        The Broadtask platform is built to create sophisticated interactions and advanced system integrations for globally deployed systems.
                        The platform provides an out-of-the-box framework built on six core services.
                    </Typography>
                    <img src="/products/broadtask/broadtask_cores.png" height={window.innerHeight >= 1000 ? 420: 150} />
                </Stack>
                <Typography variant="body1">
                    Broadtask is a framework to build programs.
                    It is designed as a plug-and-play software platform that enables the system integrator to quickly launch capabilities, even in production environments.
                    Our Broadtask software can be installed on laptops, computers, servers, virtual machines, IoT devices, and even integrated into firmware and embedded systems.
                    The software provides a runtime integration interface, connecting it to the Broadtask network, making it taskable globally.
                    The Broadtask platform couples  devices together, coordinating activities and tasks, and deploying new functions and code over the network for continuous adaptation.
                    Other software tools in Broadtask allow the user to integrate services, such as news and social media feeds, threat intelligence, financial data, cryptocurrency streams, signals collection, ground sensors, MASINT networks, or any other data platform.
                    The framework makes it easy to merge physical devices and virtual cloud services, creating a unified environment to integrate real-time data collection with advanced distributed analysis, including the rapid deployment of artificial intelligence and machine learning algorithms.
                    It enables the movement of data and tasks between public and controlled networks, providing services to seamlessly dispatch intelligence and activities into protected networks.
                    Broadtask unifies computers, devices, and cloud services making them deployable and taskable anywhere in the world.
                </Typography>
                <Typography variant="body1">
                    Please <Link component={RouterLink} to={"/contact"} underline={"hover"}>contact us</Link> to learn more about Broadtask and schedule a demonstration.
                </Typography>
            </Stack>


        </Stack>
    </Stack>
};

export default BroadtaskPage;
