import {Link, Stack, Typography} from "@mui/material";
import {useEffect} from "react";
import {pageContainer} from "../../AppStyles";
import PageHeader from "../components/PageHeader";
import {Link as RouterLink} from "react-router-dom";
import * as React from "react";

const AboutPage = () =>
{
    useEffect(() => {

    }, []);

    return <Stack direction="column" alignItems="center">

        <Stack direction="column"
               sx={pageContainer}
               spacing={1} padding={0}>

            <PageHeader title="About Us"
                        mapBC={[
                        ]}
            />

            <Stack direction="column" padding={2} spacing={1} maxHeight={window.innerHeight - 250} overflow="auto">

                <Typography variant="h5">
                    About Us
                </Typography>
                <Typography variant="body1">
                    As technologists, we love algorithms, platforms, devices, and the rapidly evolving world of technical innovation.
                    We believe technology is an essential part of solving the most consequential challenges because we’ve done it.
                    Our team has created and deployed hundreds of technical solutions in organizations for over three decades.
                </Typography>
                <Typography variant="body1">
                    As intellectuals, we love taking on arduous problems, the ones that require rolling up your sleeves and doing the work to understand the complexities and sophisticated requirements that organizations are facing today.
                    As Einstein said, <i>“It is not that I’m so smart.  But I stay with the questions much longer.”</i>
                </Typography>
                <Typography variant="body1">
                    As creatives, we love inventing new products that empower users with bold new capabilities.
                    We obsess about user experience, interaction, and comprehension.
                    We relentlessly attack cognitive load and laser focus on clarity, confidence, and decision-making.
                </Typography>
                <Typography variant="body1">
                    As entrepreneurs, we love bringing new products to market, building teams to deploy and support cutting edge innovations to support users operating around the world.
                </Typography>
                <Typography variant="body1">
                    And in the end, we are philosophers who love working on the problems that matter.
                    We seek out the significance challenges and are dedicated to doing consequential work that makes a difference.
                </Typography>
                <Typography variant="body1">
                    Our name is an algorithm for one of our favorite maxims: play or go home.
                    We show up every day ready to play, bringing our skills and experience to understand the problem and invent solutions.
                    Play XOR Go because there is no other path to success.
                </Typography>
            </Stack>

        </Stack>

    </Stack>
};

export default AboutPage;
