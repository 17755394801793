import {createTheme, CssBaseline, responsiveFontSizes, Stack, ThemeProvider} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pxg/pages/HomePage";
import AppHeader from "./pxg/components/AppHeader";
import AppFooter from "./pxg/components/AppFooter";
import BroadtaskPage from "./pxg/pages/products/BroadtaskPage";
import DSCPage from "./pxg/pages/projects/DSCPage";
import CIPage from "./pxg/pages/projects/CIPage";
import AboutPage from "./pxg/pages/AboutPage";
import ContactPage from "./pxg/pages/ContactPage";
import SIGPage from "./pxg/pages/projects/SIGPage";

let darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: '#182229',
    },
    typography: {
        fontSize: 14,
    },
});
darkTheme = responsiveFontSizes(darkTheme);

export default function AppMain()
{
    return <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <div className="app-main">
            <div className="app-content">
                <BrowserRouter>
                    <AppHeader />
                    <Stack minHeight={window.innerHeight - 180}>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/about" element={<AboutPage />} />
                            <Route path="/contact" element={<ContactPage />} />
                            <Route path="/products/broadtask" element={<BroadtaskPage />} />
                            <Route path="/projects/ci" element={<CIPage />} />
                            <Route path="/projects/dsc" element={<DSCPage />} />
                            <Route path="/projects/sig" element={<SIGPage />} />
                        </Routes>
                    </Stack>
                    <AppFooter />
                </BrowserRouter>
            </div>
        </div>
    </ThemeProvider>
}
