import {Link, Stack, Typography} from "@mui/material";
import {useEffect} from "react";
import {pageContainer} from "../../../AppStyles";
import PageHeader from "../../components/PageHeader";
import * as React from "react";
import {Link as RouterLink} from "react-router-dom";

const SIGPage = () =>
{
    useEffect(() => {

    }, []);

    return <Stack direction="column" alignItems="center">

        <Stack direction="column"
               sx={pageContainer}
               spacing={1} padding={0}>

            <PageHeader title="Semantic Intelligence"
                        mapBC={[
                            {route: "/", display: "Projects"},
                        ]}
            />

            <Stack direction="column" padding={2} spacing={1} maxHeight={window.innerHeight - 250} overflow="auto">

                <Typography variant="h5">
                    Semantic Intelligence (SI)
                </Typography>
                <Typography variant="body1">
                    We believe it is time for a new user experience that processes any type of information to build an environment for analysts to <b>explore</b> intelligence within the context of their domain.
                </Typography>
                <Typography variant="body1">
                    One of the enduring challenges in intelligence is context resolution, the process of understanding the meaning and significance of information within a specific domain or mission.
                    Data on its own can be ambiguous or incomplete, often requiring other complimentary data sources to adequately enrich it to place it in the context of who, what, when, where, why, and how.
                    These fundamental elements of intelligence are typically domain specific, meaning the mention of an organization in a data stream might be irrelevant to some and critical to another.
                    Context is subjective, dynamic, and can often be retroactive.
                    Sometimes the importance of a specific entity is not known until after an event has occurred, requiring the analyst to re-examine previous data.
                </Typography>
                <Typography variant="body1">
                    In the modern world of increasing volumes of data, often streaming in real-time from around the world, the cognitive load on the intelligence analyst has dramatically increased.
                    Part of this challenge includes expanded sources of information from different locations, data formats, multiple sources, and languages.
                    This has created a cognitive scaling issue on the analyst, trying to leverage a wide range of data sources without becoming overwhelmed by the fundamental task of reading and contextualizing information.
                    The goal of the analyst is to discover intelligence, relevant novel information specific to their domain and objectives.
                </Typography>
                <Typography variant="body1">
                    This is one of the grand challenges of our place in the technology revolution, discovering new ways to process massive volumes of data and produce a user experience that is accessible, usable, and relevant to the specific requirements of every user.
                    We need to leverage AI and ML technologies but avoid cognitive bias that could mislead or misrepresent contextual relevancy.
                    We need a new user experience for the intelligence analyst, but it starts with a novel approach in the way we process, store, and navigate source information.
                </Typography>
                <Typography variant="body1">
                    Our effort is focused on the development of an ontology, taxonomy, and modelling platform that can be trained on specific domains to automatically identify contextual intelligence elements in any data stream in any format from any source in any language.
                    The Semantic Intelligence (SI) platform processes any type of information, rendering a semantic intelligence graph (SIG) that can be explored.
                </Typography>
                <Typography variant="body1">
                    Please <Link component={RouterLink} to={"/contact"} underline={"hover"}>contact us</Link> to discuss our Semantic Intelligence platform and services.
                </Typography>
            </Stack>

        </Stack>

    </Stack>
};

export default SIGPage;
