import {Link, Stack, Typography} from "@mui/material";
import {useEffect} from "react";
import {pageContainer} from "../../../AppStyles";
import PageHeader from "../../components/PageHeader";
import * as React from "react";
import {Link as RouterLink} from "react-router-dom";

const DSCPage = () =>
{
    useEffect(() => {

    }, []);

    return <Stack direction="column" alignItems="center">

        <Stack direction="column"
               sx={pageContainer}
               spacing={1} padding={0}>

            <PageHeader title="Digital Supply Chain"
                        mapBC={[
                            {route: "/", display: "Focus Areas"},
                        ]}
            />

            <Stack direction="column" padding={2} spacing={1} maxHeight={window.innerHeight - 250} overflow="auto">

                <Typography variant="h5">
                    Digital Supply Chain (DSC)
                </Typography>

                <Typography variant="body1">
                    Play XOR Go is building methodologies and supporting technology tools and services to examine internet service providers, web services, software platforms and libraries, network appliances, hardware, firmware, and other assets that are involved in providing all forms of technology products and services.
                    From website to mobile applications to firmware, from libraries to platforms to services, from hosting providers to network providers, the digital supply chain is global, dynamic, and evolving at a rapid rate.
                    Many of the core technologies that organizations rely on everyday are deeply dependent on the reliability and trustworthiness of this global network of providers.
                </Typography>
                <Typography variant="body1">
                    Our team is continuously evaluating emerging technologies, products, and providers to assess their reliability and risk when deployed within an organization’s technical infrastructure.
                    We have created training, procedures, data services, products, and technical services to help organizations assess and secure their digital supply chain.
                    The goal of this expansive project is to identify, assess, and mitigate risks in the digital supply chain, particularly within the essential information technology providers used in critical infrastructure industries.
                </Typography>
                <Typography variant="body1">
                    Please <Link component={RouterLink} to={"/contact"} underline={"hover"}>contact us</Link> to schedule a Digital Supply Chain brief and demonstration.
                </Typography>

            </Stack>

        </Stack>

    </Stack>
};

export default DSCPage;
