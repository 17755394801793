import {Button, Stack, TextField, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {pageContainer} from "../../AppStyles";
import PageHeader from "../components/PageHeader";
import {appMessaging} from "../PXG";
import {apiCaller} from "../api/PXGAPICaller";

const ContactPage = () =>
{
    const [contactName, setContactName] = useState("");
    const [contactTitle, setContactTitle] = useState("");
    const [contactCompany, setContactCompany] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactMessage, setContactMessage] = useState("");

    useEffect(() => {

    }, []);

    function clearForm()
    {
        setContactName("");
        setContactTitle("");
        setContactCompany("");
        setContactEmail("");
        setContactPhone("");
        setContactMessage("");
    }

    function submitForm()
    {
        if (!contactName || !contactEmail || !contactMessage)
        {
            appMessaging.showError("Please provide a name, email, and contact message", 10000);
            return;
        }

        const message = {
            name: contactName,
            title: contactTitle,
            company: contactCompany,
            emailAddress: contactEmail,
            phoneNumber: contactPhone,
            message: contactMessage
        }

        apiCaller.contact(message, (result, statusMessage) => {
            if (result)
            {
                appMessaging.showSuccess("Thank you for contacting us. We will be in touch soon.", 15000);
                clearForm();
            }
            else
            {
                appMessaging.showError("An error occurred. Please try again", 10000);
            }
        });
    }

    return <Stack direction="column" alignItems="center">

        <Stack direction="column"
               sx={pageContainer}
               spacing={1} padding={0}>

            <PageHeader title="Contact Us"
                        mapBC={[
                        ]}
            />

            <Stack direction="column" padding={2} spacing={1} maxHeight={window.innerHeight - 250} overflow="auto" width="100%">

                <Typography variant="body1">
                    Please provide us some contact information so we can reach out to setup a time for an initial discussion.
                </Typography>

                <Stack direction="column" spacing={1} width="100%">
                    <TextField label="Name" fullWidth={true} size="small" value={contactName} type="text" onChange={(event) => { setContactName(event.target.value); }} />
                    <TextField label="Title" fullWidth={true} size="small" value={contactTitle} type="text" onChange={(event) => { setContactTitle(event.target.value); }} />
                    <TextField label="Company" fullWidth={true} size="small" value={contactCompany} type="text" onChange={(event) => { setContactCompany(event.target.value); }} />
                    <TextField type="email" label="Email Address" fullWidth={true} size="small" value={contactEmail} type="text" onChange={(event) => { setContactEmail(event.target.value); }} />
                    <TextField label="Phone Number" fullWidth={true} size="small" value={contactPhone} type="text" onChange={(event) => { setContactPhone(event.target.value); }} />
                    <TextField label="Message" fullWidth={true} multiline={true} rows={6} size="small" value={contactMessage} type="text" onChange={(event) => { setContactMessage(event.target.value); }} />
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="center">
                    <Button onClick={clearForm}>Clear</Button>
                    <Button onClick={submitForm}>Submit</Button>
                </Stack>
            </Stack>

        </Stack>

    </Stack>
};

export default ContactPage;
