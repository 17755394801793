import {useFrame} from "@react-three/fiber";
import {useEffect} from "react";
import {DoubleSide} from "three";
import {useMatcapTexture} from "@react-three/drei";

export default function HexFloor({
    position
                                    })
{
    const [ matcapBlack ] = useMatcapTexture('0A0A0A_A9A9A9_525252_747474', 256);

    const ringStart = 0.0;
    const ringRadius = 7.8;

    useEffect(() => {

    }, [])

    useFrame((state, delta, frame) => {

    })

    return <group position={position} rotation-y={Math.PI/2}>
        <mesh position={[0,0, 0]} sides={DoubleSide} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} />
            {/*<meshBasicMaterial*/}
            {/*    color={'#AAF0F0'} wireframe={false} />*/}
        </mesh>

        <mesh position={[13.5, 0, -3.6]} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} />
        </mesh>

        <mesh position={[9.9, 0, 9.9]} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} side="DoubleSide" />
        </mesh>

        <mesh position={[-3.6, 0, 13.5]} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} />
        </mesh>

        <mesh position={[-13.5, 0, 3.6]} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} />
        </mesh>

        <mesh position={[-9.9, 0, -9.9]} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} />
        </mesh>

        <mesh position={[3.6, 0, -13.5]} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} />
        </mesh>

        <mesh position={[17.1, 0, -17.1]} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} />
            {/*<meshBasicMaterial*/}
            {/*    color={'#500000'} wireframe={false} />*/}
        </mesh>

        <mesh position={[23.3, 0, 6.4]} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} />
            {/*<meshBasicMaterial*/}
            {/*    color={'#500000'} wireframe={false} />*/}
        </mesh>

        <mesh position={[6.25, 0, 23.4]} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} />
            {/*<meshBasicMaterial*/}
            {/*    color={'#500000'} wireframe={false} />*/}
        </mesh>

        <mesh position={[-17.1, 0, 17.1]} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} />
            {/*<meshBasicMaterial*/}
            {/*    color={'#500000'} wireframe={false} />*/}
        </mesh>

        <mesh position={[-23.3, 0, -6.4]} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} />
            {/*<meshBasicMaterial*/}
            {/*    color={'#500000'} wireframe={false} />*/}
        </mesh>

        <mesh position={[-6.25, 0, -23.4]} rotation-y={Math.PI} rotation-x={Math.PI/2}>
            <ringGeometry args={[ringStart, ringRadius, 6, 1, Math.PI/4]} />
            <meshMatcapMaterial matcap={matcapBlack} sides={DoubleSide} />
            {/*<meshBasicMaterial*/}
            {/*    color={'#500000'} wireframe={false} />*/}
        </mesh>
    </group>
}
